import React, { useMemo, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MUIAutocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { MixTheme } from '@styles';

interface AutoCompleteProps {
    onChange: (data: any) => void;
    searchData: any[];
    value?: any;
    defaultValue?: any;
    label?: string;
    labelKey: string;
    error?: string;
    valueKey: string;
    renderInput?: any;
    name?: string;
    width?: string;
    getOptionLabel?: (option: any) => string;
    [props: string]: any;
    containerStyle?: SxProps<Theme>;
    placeholder?: string;
    sx?: SxProps<Theme>;
    multiple?: boolean;
    limitTags?: number;
    disabled?: boolean;
    isRequired?: boolean;
}
export const AutoComplete = ({
    onChange,
    searchData,
    value,
    name,
    defaultValue,
    label,
    labelKey,
    error,
    valueKey,
    renderInput,
    width,
    getOptionLabel,
    containerStyle,
    disabled = false,
    isRequired = false,
    ...props
}: AutoCompleteProps) => {
    const theme: MixTheme = useTheme();
    const [selectedValue, setSelectedValue] = useState(value ?? '');

    useEffect(() => {
        if (searchData && value) {
            const item = searchData.find((ele) => {
                return (
                    ele[valueKey] === value ||
                    ele[valueKey] === value?.[valueKey]
                );
            });
            setSelectedValue(item);
        }
    }, [value, searchData, valueKey]);

    const onChangeValue = React.useCallback(
        (newValue: any) => {
            if (onChange && newValue) {
                setSelectedValue(newValue);
                onChange(newValue);
            }
        },
        [onChange]
    );

    const dropDownLabel = useMemo(() => (label ? label : ''), [label]);

    return (
        <Box component="form" sx={{ width: '100%', ...containerStyle }}>
            {dropDownLabel && (
                <Grid
                    sx={{
                        display: 'flex',
                        marginBottom: '8px',
                        gap: isRequired ? '0.2rem' : undefined
                    }}
                >
                    <Typography
                        component={'label'}
                        sx={{
                            fontFamily: theme?.font?.tertiary,
                            padding: '0'
                        }}
                    >
                        {dropDownLabel}
                    </Typography>
                    {isRequired && (
                        <Typography sx={{ color: theme?.color?.error }}>
                            *{' '}
                        </Typography>
                    )}
                </Grid>
            )}

            <MUIAutocomplete
                disablePortal
                disableClearable
                disabled={disabled}
                id="combo-box-demo"
                onChange={(e, newValue) => {
                    onChangeValue(newValue);
                }}
                multiple={props?.multiple ?? false}
                options={searchData}
                defaultValue={defaultValue}
                value={props?.multiple ? undefined : selectedValue}
                limitTags={props?.limitTags}
                sx={{
                    ...(props?.sx ?? {}),
                    display: 'flex',

                    '& input': {
                        padding: '0'
                    },
                    width: width
                }}
                componentName={name}
                renderInput={(params) =>
                    renderInput?.(params) ?? (
                        <TextField
                            {...params}
                            name={name}
                            placeholder={props.placeholder ?? ''}
                            sx={{
                                fieldset: {
                                    border: error
                                        ? `1px solid${theme?.color?.error} `
                                        : undefined
                                }
                            }}
                        />
                    )
                }
                isOptionEqualToValue={(option: any, Optionvalue: any) =>
                    option[valueKey] === Optionvalue ||
                    option[valueKey] === Optionvalue?.[valueKey]
                }
                getOptionLabel={(option: any) =>
                    getOptionLabel?.(option) ?? option[labelKey] ?? ''
                }
                {...props}
            />
            {error && (
                <FormHelperText
                    error={true}
                    sx={{ '&.Mui-error': { color: 'red' }, fontSize: '1rem' }}
                >
                    {error}
                </FormHelperText>
            )}
        </Box>
    );
};
