import { gql } from '@apollo/client';
export const usersListQuery = gql`
    query ($limit: Int!, $offset: Int!) {
        listUsers(limit: $limit, offset: $offset) {
            users {
                role
                externalUserId
                kycStatus
                avatar
                organization {
                    name
                }
                user {
                    id
                    username
                    email
                    name
                }
            }
            pagination {
                offset
                totalCount
            }
        }
    }
`;

export const listOrganizationCustomerQuery = gql`
    query (
        $offset: Int
        $limit: Int
        $orgID: UUID1!
        $filter: [FilterUserInput]
        $search: String
    ) {
        listOrgCustomer(
            offset: $offset
            limit: $limit
            orgID: $orgID
            filter: $filter
            search: $search
        ) {
            users {
                id
                userId
                organizationId
                username
                name
                externalId
                role
                email
                walletAddress
                KycStatus
                createdAt
                totalCount
                notes
                is_blocked
                totalCount
                FirstName
                LastName
            }
            pagination {
                offset
                totalCount
            }
        }
    }
`;

export const customerQuery = gql`
    query (
        $handle: String!
        $limit: Int!
        $offset: Int!
        $filter: OrganizationMemberFilter! = {}
    ) {
        organization(handle: $handle) {
            id
            handle
            name
            members(limit: $limit, offset: $offset, filter: $filter) {
                username
                id
                name
                role
                email
                KycStatus
                walletAddress
                totalCount
                externalId
            }
        }
    }
`;

export const organizationQuery = gql`
    query ($limit: Int!, $offset: Int!, $orgfilter: [FilterOrgInput]) {
        listOrganizations(
            limit: $limit
            offset: $offset
            orgfilter: $orgfilter
        ) {
            data {
                id
                mor
                name
                createdAt
                totalUsers
                wallets {
                    id
                    address
                    isExternal
                }
            }

            pagination {
                offset
                totalCount
            }
        }
    }
`;
//for wallet Address
export const organizationInfo = gql`
    query ($id: UUID1!) {
        organizationByID(id: $id) {
            id
            createdAt
            mor
            notes
            wallets {
                address
                isExternal
                parentType
            }
        }
    }
`;
//for  organizationUsers
export const OrgUsers = gql`
    query ($limit: Int!, $offset: Int!, $filter: [FilterUserInput]) {
        listMintUsers(limit: $limit, offset: $offset, filter: $filter) {
            users {
                id
                userId
                username
                role
                user {
                    name
                    email
                    createdAt
                }
                organizationId
                organization {
                    name
                }
                profilePic
            }
            pagination {
                totalCount
                offset
            }
        }
    }
`;

export const deleteOrganization = gql`
    mutation ($orgID: UUID1!) {
        deleteOrg(orgID: $orgID)
    }
`;

export const meQuery = gql`
    query Me {
        me {
            wallets {
                id
                gnosisSafeURL
                name
                address
                tokens {
                    contractAddress
                    id
                }
                network {
                    name
                    chainID
                }
            }
            userOrgs {
                id
                role
                bidAllowed
                profilePic
                kycStatus
                avatar
                profilePic
                organization {
                    handle
                    name
                    id
                    jwtIssuerDomain
                    mor
                    afterPaymentTransferSuspendTime
                    buyNowInvoiceExpiryMins
                    auctionInvoiceExpiryMins
                    purchaseLimitWithoutKYC
                    marketplaces {
                        isSecondaryMarketPlace
                        isPrimaryMarketPlace
                        id
                        name
                        collections {
                            redirectUrl
                        }
                    }
                }
            }
            user {
                id
                username
                email
                name
                isTermsAccepted
            }
        }
    }
`;

//for add Organization
export const addNeworganization = gql`
    mutation addOrganization($name: String!, $handle: String!) {
        addOrganization(name: $name, handle: $handle) {
            id
            name
            handle
            notes
            mor
            createdAt
            totalUsers
        }
    }
`;

export const listOrganizations = gql`
    query ($limit: Int!, $offset: Int!) {
        listOrganizations(limit: $limit, offset: $offset) {
            data {
                id
                name
            }
        }
    }
`;

export const createUser = gql`
    mutation createUser(
        $name: String!
        $email: String!
        $walletAddress: String!
        $orgID: UUID1!
        $role: String!
    ) {
        createUser(
            name: $name
            email: $email
            walletAddress: $walletAddress
            orgID: $orgID
            role: $role
        ) {
            id
            username
            name
            email
        }
    }
`;

export const deleteMintUserQuery = gql`
    mutation deleteMintUser($userId: UUID1!) {
        deleteUser(userId: $userId)
    }
`;

export const changeMintUserPasswordQuery = gql`
    mutation changeMintUserPassword($userId: UUID1!, $orgId: UUID1!) {
        changePassword(userID: $userId, orgID: $orgId)
    }
`;

export const orgCreateMarketplace = gql`
    mutation orgCreateMarketplace($name: String!, $orgId: UUID1!) {
        orgCreateMarketplace(name: $name, orgId: $orgId) {
            id
            name
        }
    }
`;

export const addUserToOrgQuery = gql`
    mutation addUserOrgRole($userID: UUID!, $orgID: UUID!, $role: String!) {
        addUserOrgRole(userID: $userID, orgID: $orgID, role: $role) {
            id
            userId
            organizationId
        }
    }
`;

export const Organization = gql`
    query ($handle: String!) {
        organization(handle: $handle) {
            taxExempt
            secondaryWebsiteUrl
            handle
            name
            id
            jwtIssuerDomain
            mor
            isGasPaidByUser
            buyNowInvoiceExpiryMins
            auctionInvoiceExpiryMins
            purchaseLimitWithoutKYC
            afterPaymentTransferSuspendTime
            marketplaces {
                id
                name
                theme
                isSecondaryMarketPlace
                isPrimaryMarketPlace
                __typename
            }
            nftContracts {
                contractAddress
                id
                name
                symbol
                marketplaceAddress
                network {
                    name
                }
                nftContractType {
                    name
                    id
                    __typename
                }
                nftContractDeployment {
                    collectionType
                }
                deploymentTxHash
                mediaTxHash
                transferOwnershipHash
                activationTxHash
                wallet {
                    id
                    address
                    name
                    gnosisSafeURL
                    network {
                        name
                        rpcURL
                        chainID
                        __typename
                        id
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const getAllowListsQuery = gql`
    query (
        $orgID: UUID1!
        $sort: SortInputAllowLists
        $filter: FilterAllowListsType
    ) {
        getAllowLists(orgID: $orgID, sort: $sort, filter: $filter) {
            id
            name
            identifierType
            createdAt
            updatedAt
            allowListType
            elements {
                id
                identifierValue
            }
        }
    }
`;

export const getAllowListSettingsQuery = gql`
    query ($allowListID: UUID1!) {
        getAllowListSettings(allowListID: $allowListID) {
            id
            allowListID
            contractAddress
            tokenId
            network
            ownedWalletAddress
            ownedCondition
            settingCondition
        }
    }
`;

export const createAllowListSettingQuery = gql`
    mutation (
        $allowListID: UUID1!
        $contractAddress: String!
        $tokenId: String!
        $network: UUID1!
        $ownedWalletAddress: Int!
        $ownedCondition: OwnedCondition!
        $settingCondition: SettingCondition
    ) {
        createAllowListSetting(
            input: {
                allowListID: $allowListID
                contractAddress: $contractAddress
                tokenId: $tokenId
                network: $network
                ownedWalletAddress: $ownedWalletAddress
                settingCondition: $settingCondition
                ownedCondition: $ownedCondition
            }
        ) {
            id
            allowListID
            contractAddress
            tokenId
            network
            ownedWalletAddress
        }
    }
`;

export const deleteAllowListSettingQuery = gql`
    mutation ($allowListSettingID: UUID1!) {
        deleteAllowListSetting(allowListSettingID: $allowListSettingID)
    }
`;

export const createEmptyAllowListQuery = gql`
    mutation (
        $orgID: UUID1!
        $name: String!
        $allowListType: AllowListType!
        $identifierType: IdentifierType
    ) {
        createEmptyAllowList(
            orgID: $orgID
            allowListInput: {
                name: $name
                allowListType: $allowListType
                identifierType: $identifierType
            }
        )
    }
`;

export const updateAllowListQuery = gql`
    mutation ($orgID: UUID1!, $allowListID: UUID1!, $elementsToAdd: [String!]) {
        updateAllowList(
            orgID: $orgID
            allowListID: $allowListID
            elementsToAdd: $elementsToAdd
        )
    }
`;

export const updateUserNotesQuery = gql`
    mutation ($userID: UUID1!, $note: String!) {
        updateUserNote(userID: $userID, note: $note)
    }
`;

export const getWalletsBasedOnRulesQuery = gql`
    query (
        $orgID: UUID1!
        $contractAddress: String!
        $startDate: Time
        $endDate: Time
        $network: UUID1!
        $tokenIds: [TokenIdsRange]
        $ownedCondition: OwnedSearchCondition
    ) {
        getWalletsBasedOnRules(
            orgID: $orgID
            contractAddress: $contractAddress
            startDate: $startDate
            endDate: $endDate
            network: $network
            tokenIds: $tokenIds
            ownedCondition: $ownedCondition
        ) {
            walletAddress
            tokenID
            walletValue
            totalOwned
            contractAddress
        }
    }
`;

export const createAllowListQuery = gql`
    mutation (
        $orgID: UUID1!
        $filteringType: FilteringType!
        $identifierType: IdentifierType!
        $startTime: Time!
        $endTime: Time!
        $name: String!
        $description: String!
        $allowListType: AllowListType
        $elements: [String!]!
    ) {
        createAllowList(
            orgID: $orgID
            allowListInput: {
                filteringType: $filteringType
                identifierType: $identifierType
                startTime: $startTime
                endTime: $endTime
                name: $name
                description: $description
                allowListType: $allowListType
                elements: $elements
            }
        )
    }
`;

export const customerDetailsQuery = gql`
    query (
        $orgID: UUID1!
        $userID: UUID1!
        $filter: [InvoiceFilterInput]!
        $limit: Int!
        $page: Int!
        $searchKey: String
    ) {
        getInvoicesByUserIDPage(
            orgID: $orgID
            userID: $userID
            filter: $filter
            limit: $limit
            page: $page
            searchKey: $searchKey
        ) {
            data {
                userName
                invoiceID
                invoiceNumber
                invoiceCreatedAt
                externalUserID
                items {
                    invoiceItemID
                    collectionItemID
                    collectionTitle
                    collectionItemTitle
                    destinationAddress
                    units
                    unitPrice
                    buyersPremium
                    overheadPremium
                    totalPrice
                    taxes
                    salesTaxRate
                    saleTimeslot
                    isOnchainPaymentAvailable
                }
                internalUserID
                status
            }
            pagination {
                offset
                totalCount
            }
        }
    }
`;

export const getAllowListByIdQuery = gql`
    query ($orgID: UUID1!, $allowListID: UUID1!) {
        getAllowList(orgID: $orgID, allowListID: $allowListID) {
            id
            filteringType
            identifierType
            name
            description
            createdAt
            updatedAt
            allowListType
            elements {
                id
                identifierValue
                createdAt
            }
        }
    }
`;

export const removeAndUpdateAllowListQuery = gql`
    mutation (
        $orgID: UUID1!
        $allowListID: UUID1!
        $elementsToRemove: [String!]
    ) {
        updateAllowList(
            orgID: $orgID
            allowListID: $allowListID
            elementsToRemove: $elementsToRemove
        )
    }
`;

export const getSupportedNetworksByOrganizationQuery = gql`
    query ($orgId: UUID1!) {
        getSupportedNetworksByOrganization(orgId: $orgId) {
            id
            networkId
            dutchAuctionContractAddress
            onchainAuctionContractAddress
            dutchUtilityContractAddress
        }
    }
`;

export const getUSDPriceQuery = gql`
    query ($CryptoCurrenyCode: CryptoCurrenyCode!) {
        getUSDPrice(cryptoCurrencyCode: $CryptoCurrenyCode) {
            amount
            currency
            base
        }
    }
`;
export const getAllNetworkQuery = gql`
    query {
        getAllNetwork {
            id
            name
        }
    }
`;
