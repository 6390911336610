import { Box, Stack, Typography, useTheme } from '@mui/material';
import Link from 'next/link';
import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect
} from 'react';
import { makeStyles } from './submenu.styles';
import { MixTheme, useStyle } from '@styles';
import { RouterPath, StoreProviderSkipRequests } from '@constants';
import { useMutation } from '@apollo/client';
import {
    createEmptyAllowListQuery,
    createAllowListQuery
} from '@graphql/queries/query';
import { useStore } from '@state/StoreProvider';
import { ModalDialog } from '@components/Atoms';
import {
    CreateAllowListModalBodyLayout,
    InvalidEntriesModalBodyLayout,
    UploadCompleteLayout
} from '@layouts/modalBodyLayouts/CreateAllowListModalBodyLayout';
import { useRouter } from 'next/router';
import {
    AllowlistTypeProps,
    AddAuthId,
    GetAllowList
} from '@interface/Allowlist';
import Papa from 'papaparse';
import { LoadingComponent } from '@components/shared';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useGlobalState } from '@state/GlobalContext';
import { convertSortValue } from '@utils/formatStings.utils';

const AllowListSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    allowlistType: Yup.string().required('Required')
});

const CustomersSubMenuLayout = () => {
    const { styles } = useStyle(makeStyles);
    const theme: MixTheme = useTheme();
    const {
        allowlistData,
        currentOrganizationId,
        allowListFilter,
        refetchAllowlistData,
        setSkipRequests
    } = useStore();
    const { setData: setGlobalData } = useGlobalState();
    const router = useRouter();

    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [allowlistTypeFields] = useState<AllowlistTypeProps>({
        name: '',
        allowlistType: [
            { label: 'Manual', value: 'Manual' },
            { label: 'Dynamic', value: 'Dynamic' }
        ]
    });

    const formikRef = useRef() as React.MutableRefObject<
        FormikProps<{
            name: string;
            allowlistType: string;
            identifierType?: string;
        }>
    >;
    const [isOpenUploadCompleteSuccess, setIsOpenUploadCompleteSuccess] =
        useState<boolean>(false);
    const [createEmptyAllowList, { loading: createEmptyAllowListLoading }] =
        useMutation(createEmptyAllowListQuery);
    const [isOpenNewAllowlistModal, setIsOpenNewAllowlistModal] =
        useState(false);
    const [formState, setFormState] = useState({
        name: '',
        allowlistType: '',
        identifierType: ''
    });
    const [isOpenvalidEntriesModal, setIsOpenvalidEntriesModal] =
        useState<boolean>(false);
    const [createAllowList, { loading: createAllowListLoading }] =
        useMutation(createAllowListQuery);

    const [bulkUploadFileDetails, setBulkUploadFileDetails] = useState({
        fileName: '',
        fileSize: ''
    });

    const [isActive, setIsActive] = useState({});
    const [values, setValues] = useState<AddAuthId[]>([]);

    useEffect(() => {
        setSkipRequests({
            ...StoreProviderSkipRequests,
            allowListQuery: false,
            meQuery: false
        });
    }, [setSkipRequests]);

    useEffect(() => {
        setGlobalData((prev) => ({
            ...prev,
            isRefetchAllowList: true
        }));
    }, [setGlobalData]);

    const onReadAndProcessFileHandler = useCallback(
        (event) => {
            if (
                event.target.files.length > 0 &&
                Math.round(event.target.files[0].size / 2048) < 2048
            ) {
                Papa.parse(event.target.files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        const valuesArray: AddAuthId[] = [];
                        const identifierType =
                            formikRef.current?.values?.identifierType;
                        const pattern =
                            identifierType === 'ExternalUserID'
                                ? /([a-zA-Z0-9]+)\|([a-zA-Z0-9]+)/
                                : /^0x[a-fA-F0-9]{40}$/;

                        results.data.map((d: any) => {
                            const resultValues: string[] = Object.values(d);
                            valuesArray.push({
                                authId: resultValues[0].toString(),
                                isValid: pattern.test(
                                    resultValues[0].toString()
                                )
                            } as AddAuthId);
                        });
                        setValues(valuesArray);
                    }
                });

                const fileSize = event.target.files[0].size / 1000000 + 'MB';

                setBulkUploadFileDetails({
                    ...bulkUploadFileDetails,
                    fileName: event.target.files[0].name,
                    fileSize: fileSize
                });
            }
        },
        [bulkUploadFileDetails, formikRef]
    );

    const onHandleDeleteUploadedFile = useCallback(() => {
        if (inputFileRef?.current) inputFileRef.current.value = '';
        setBulkUploadFileDetails({
            ...bulkUploadFileDetails,
            fileName: '',
            fileSize: ''
        });
    }, [inputFileRef, bulkUploadFileDetails]);

    const onSubmitNewAllowlist = useCallback(async () => {
        try {
            formikRef.current?.validateForm();
        } catch (e) {
            console.error('error', e);
        }
        const name = formikRef?.current?.values?.name;
        const allowlistType = formikRef?.current?.values?.allowlistType;
        if (
            name !== '' &&
            allowlistType !== '' &&
            formikRef?.current?.isValid
        ) {
            if (allowlistType === 'Manual' || allowlistType === 'Dynamic') {
                await createEmptyAllowList({
                    variables: {
                        orgID: currentOrganizationId,
                        name: name,
                        allowListType: allowlistType,
                        identifierType: 'WalletAddress'
                    }
                });
                const data = await refetchAllowlistData({
                    orgID: currentOrganizationId
                });
                const result: GetAllowList[] = data.data.getAllowLists;

                router.push({
                    pathname:
                        allowlistType === 'Manual'
                            ? RouterPath.allowListsDetail
                            : RouterPath.allowListsSetting,
                    query: {
                        id: result[0].id,
                        sort: convertSortValue(allowListFilter?.sort),
                        filter: allowListFilter.filter
                    }
                });
                setIsOpenNewAllowlistModal(false);
            } else if (
                allowlistType === 'Bulk' &&
                inputFileRef.current?.value !== null
            ) {
                const identifierType =
                    formikRef?.current?.values?.identifierType ?? '';
                setFormState({
                    name,
                    allowlistType,
                    identifierType
                });
                const hasError: AddAuthId | undefined = values.find(
                    (ele: AddAuthId) => ele.isValid === false
                );
                if (hasError) {
                    setIsOpenNewAllowlistModal(false);
                    setIsOpenvalidEntriesModal(true);
                } else {
                    const auth0IDs: string[] = values.map(
                        (ele: AddAuthId) => ele.authId
                    );
                    await createAllowList({
                        variables: {
                            orgID: currentOrganizationId,
                            filteringType: 'AllowList',
                            identifierType: identifierType,
                            startTime: new Date().toISOString(),
                            endTime: new Date().toISOString(),
                            name: name,
                            description: 'Bulk Allowlist',
                            allowListType: allowlistType,
                            elements: auth0IDs
                        }
                    });
                    onHandleDeleteUploadedFile();
                    setIsOpenNewAllowlistModal(false);
                    setIsOpenvalidEntriesModal(false);
                    const data = await refetchAllowlistData({
                        orgID: currentOrganizationId
                    });
                    const result: GetAllowList[] = data.data.getAllowLists;

                    router.push({
                        pathname: RouterPath.allowListsDetail,
                        query: {
                            id: result[0].id,
                            sort: convertSortValue(allowListFilter?.sort),
                            filter: allowListFilter.filter
                        }
                    });
                    setIsOpenUploadCompleteSuccess(true);
                }
            }
        }
    }, [
        createEmptyAllowList,
        currentOrganizationId,
        refetchAllowlistData,
        values,
        createAllowList,
        onHandleDeleteUploadedFile,
        allowListFilter,
        formikRef,
        router
    ]);

    const onInvalidEntriesSubmit = useCallback(async () => {
        const auth0IDs: string[] = values.map((ele: AddAuthId) => {
            return ele.authId;
        });

        await createAllowList({
            variables: {
                orgID: currentOrganizationId,
                filteringType: 'AllowList',
                identifierType: formState.identifierType,
                startTime: new Date().toISOString(),
                endTime: new Date().toISOString(),
                name: formState.name,
                description: 'Bulk Allowlist',
                allowListType: formState.allowlistType,
                elements: auth0IDs
            }
        });
        onHandleDeleteUploadedFile();
        setIsOpenNewAllowlistModal(false);
        setIsOpenvalidEntriesModal(false);
        const data = await refetchAllowlistData({
            orgID: currentOrganizationId
        });
        const result: GetAllowList[] = data.data.getAllowLists;

        router.push({
            pathname: RouterPath.allowListsDetail,
            query: {
                id: result[0].id,
                sort: convertSortValue(allowListFilter?.sort),
                filter: allowListFilter.filter
            }
        });
        setIsOpenUploadCompleteSuccess(true);
    }, [
        values,
        createAllowList,
        currentOrganizationId,
        onHandleDeleteUploadedFile,
        refetchAllowlistData,
        formState,
        router,
        allowListFilter
    ]);

    const onUploadCompleteSubmit = useCallback(() => {
        setIsOpenUploadCompleteSuccess(false);
    }, []);

    const onCloseDialog = useCallback(() => {
        setIsOpenUploadCompleteSuccess(false);
        setIsOpenvalidEntriesModal(false);
    }, []);

    const onCloseAllowListModal = useCallback(() => {
        setIsOpenNewAllowlistModal(false);
    }, []);

    const allowList = useMemo(
        () => allowlistData?.getAllowLists,
        [allowlistData?.getAllowLists]
    );

    const isSelectedId = useMemo(() => router.query.id, [router]);

    if (createEmptyAllowListLoading || createAllowListLoading) {
        return (
            <LoadingComponent
                show={createEmptyAllowListLoading || createAllowListLoading}
            />
        );
    }
    return (
        <>
            <Stack padding={'20px 24px 20px 30px'}>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 600,
                        fontSize: '24px',
                        fontFamily: theme?.font?.sneak
                    }}
                >
                    Community
                </Typography>
            </Stack>
            <Stack padding={'0 24px 8px 30px'}>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme?.color?.textColor,
                            fontWeight: '700',
                            fontSize: '12px',
                            fontFamily: theme?.font?.sneak
                        }}
                    >
                        CUSTOMERS
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={'0 24px 0 24px'}>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Box
                        sx={{
                            ...styles.BoxForTextCustomer,
                            marginTop: 0,
                            width: '100%',
                            backgroundColor:
                                router.pathname == RouterPath.customers ||
                                router.pathname.includes('/customers/')
                                    ? theme?.global?.hoverBackground
                                    : ''
                        }}
                    >
                        <Link href={`${RouterPath.customers}`}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color:
                                        router.pathname ==
                                            RouterPath.customers ||
                                        router.pathname.includes('/customers/')
                                            ? theme?.global?.mainlyBlue
                                            : theme?.color?.textColor,
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    fontFamily: theme?.font?.sneak,
                                    cursor: 'pointer'
                                }}
                            >
                                All Customers
                            </Typography>
                        </Link>
                    </Box>
                </Stack>
            </Stack>
            <Stack padding={'10px 24px 0 24px'}>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ marginBottom: '12px' }}
                >
                    <Box>
                        <Typography variant="h5" sx={styles.allowlistsSection}>
                            Allowlists
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        sx={styles.addNewAllowlist}
                        onClick={() => setIsOpenNewAllowlistModal(true)}
                    >
                        +&nbsp;&nbsp;New
                    </Typography>
                </Stack>
                <Stack maxHeight={260} overflow={'auto'}>
                    <Box>
                        <Typography
                            variant="body1"
                            sx={styles.createdAllowlists}
                        >
                            <Link href={`${RouterPath.allowLists}`}>
                                <Typography
                                    onClick={() => setIsActive({})}
                                    sx={
                                        router.pathname ==
                                            RouterPath.allowLists &&
                                        styles.hoverColor
                                    }
                                >
                                    All Allowlists
                                </Typography>
                            </Link>
                        </Typography>
                    </Box>
                    {(router.pathname == RouterPath.allowLists ||
                        router.pathname.includes('allowlists')) &&
                        allowList &&
                        allowList.map((item: GetAllowList) => {
                            return (
                                <Box key={item.id}>
                                    <Typography
                                        variant="body1"
                                        sx={styles.createdAllowlists}
                                    >
                                        <Link
                                            href={{
                                                pathname:
                                                    item.allowListType ===
                                                    'Dynamic'
                                                        ? RouterPath.allowListsSetting
                                                        : RouterPath.allowListsDetail,
                                                query: {
                                                    id: item.id,
                                                    sort: convertSortValue(
                                                        allowListFilter?.sort
                                                    ),
                                                    filter:
                                                        allowListFilter.filter ??
                                                        'ALL'
                                                }
                                            }}
                                        >
                                            <Typography
                                                sx={
                                                    isSelectedId === item.id &&
                                                    styles.hoverColor
                                                }
                                            >
                                                {item.name}
                                            </Typography>
                                        </Link>
                                    </Typography>
                                </Box>
                            );
                        })}
                </Stack>
            </Stack>

            <ModalDialog
                title="New Allowlist"
                open={isOpenNewAllowlistModal}
                onClose={onCloseAllowListModal}
                onCancel={onCloseAllowListModal}
                onSubmit={onSubmitNewAllowlist}
                submitButtonText={
                    formikRef?.current?.values?.allowlistType === 'Bulk'
                        ? 'Continue'
                        : 'Create List'
                }
                isSubmitEnabled
                body={
                    <Formik
                        innerRef={formikRef}
                        validationSchema={AllowListSchema}
                        enableReinitialize
                        initialValues={{ name: '', allowlistType: '' }}
                        onSubmit={onSubmitNewAllowlist}
                    >
                        {({ values: formValues, setFieldValue }) => {
                            return (
                                <CreateAllowListModalBodyLayout
                                    onReadAndProcessFileHandler={
                                        onReadAndProcessFileHandler
                                    }
                                    bulkUploadFileDetails={
                                        bulkUploadFileDetails
                                    }
                                    onHandleDeleteUploadedFile={
                                        onHandleDeleteUploadedFile
                                    }
                                    inputFileRef={inputFileRef}
                                    values={formValues}
                                    setFieldValue={setFieldValue}
                                    allowListOptions={
                                        allowlistTypeFields?.allowlistType
                                    }
                                />
                            );
                        }}
                    </Formik>
                }
            />

            {/* Upload Complete */}
            <ModalDialog
                title="Upload complete"
                open={isOpenUploadCompleteSuccess}
                onClose={onCloseDialog}
                onCancel={onCloseDialog}
                onSubmit={onUploadCompleteSubmit}
                submitButtonText={'Close'}
                otherWidth={'md'}
                isSubmitEnabled
                otherImage={'/images/icons/green_tick.svg'}
                body={<UploadCompleteLayout />}
            />

            <ModalDialog
                title="Invalid entries"
                open={isOpenvalidEntriesModal}
                onClose={onCloseDialog}
                onCancel={onCloseDialog}
                onSubmit={onInvalidEntriesSubmit}
                submitButtonText={'Continue'}
                otherImage={'/images/icons/red_circle_exclamation.svg'}
                otherWidth={'md'}
                isSubmitEnabled
                body={
                    <InvalidEntriesModalBodyLayout
                        invalidEntriesArr={values}
                        updateEntries={setValues}
                        isAuth0={formState.identifierType === 'ExternalUserID'}
                    />
                }
            />
        </>
    );
};

export default CustomersSubMenuLayout;
