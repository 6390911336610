import { gql } from '@apollo/client';

export const createDraftContract = gql`
    mutation (
        $orgID: UUID1!
        $name: String!
        $symbol: String!
        $contractType: ContractType!
        $walletID: UUID1!
    ) {
        createDraftContract(
            input: {
                orgID: $orgID
                name: $name
                symbol: $symbol
                contractType: $contractType
                walletID: $walletID
            }
        ) {
            id
        }
    }
`;

export const deleteCollectionDraftQuery = gql`
    mutation ($nftContractID: UUID1!) {
        deleteDraftContract(nftContractID: $nftContractID)
    }
`;

export const uploadContractAssetQuery = gql`
    mutation ($orgId: UUID1!, $file: Upload!, $name: String!) {
        uploadAsset(orgId: $orgId, file: $file, name: $name)
    }
`;

export const deployContract = gql`
    mutation (
        $organizationId: UUID1!
        $nftName: String!
        $nftSymbol: String!
        $contractType: ContractType!
        $walletId: UUID1!
    ) {
        nftDeployContract(
            input: {
                organizationId: $organizationId
                nftName: $nftName
                nftSymbol: $nftSymbol
                contractType: $contractType
                walletId: $walletId
            }
        ) {
            contractAddress
            id
            name
            symbol
            marketplaceAddress
            nftContractType {
                name
                id
                __typename
            }
            deploymentTxHash
            mediaTxHash
            transferOwnershipHash
            activationTxHash
            wallet {
                id
                address
                name
                gnosisSafeURL
                network {
                    name
                    rpcURL
                    chainID
                    __typename
                }
                __typename
            }
        }
    }
`;

export const createTokenDraftQuery = gql`
    mutation (
        $name: String
        $description: String
        $metadataJSON: String
        $contractId: UUID1!
    ) {
        createTokenDraft(
            tokens: [
                {
                    name: $name
                    description: $description
                    metadataJSON: $metadataJSON
                }
            ]
            contractId: $contractId
        )
    }
`;

export const updateDraftContractQuery = gql`
    mutation ($nftContractID: UUID1!, $input: CollectionContract!) {
        updateDraftContract(nftContractID: $nftContractID, input: $input) {
            id
        }
    }
`;

export const nftContractListQuery = gql`
    query ($id: UUID1!) {
        nftContract(id: $id) {
            id
            nftContractType {
                id
                name
                __typename
            }
            name
            symbol
            contractAddress
            wallet {
                id
                name
                address
                network {
                    id
                    name
                    __typename
                }
                deploymentTxHash
                __typename
            }
            nftTokens {
                TokenDeploymentStatus
                id
                name
                editions
                thumbnailImageCdnURL
                deployed
                metadataJSON
                assetId
                metadataArweaveTxId
                metadataArweaveTxLink
                ethereumTxId
                royaltyBasisPoints
                status
                tokenMetadata {
                    name
                    description
                    image
                    __typename
                }
                asset {
                    currentVersion {
                        cdnUrl
                        __typename
                    }
                    __typename
                }
                onChainId
                network {
                    name
                    id
                    chainID
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const nftContractTokenListQuery = gql`
    query (
        $id: UUID1!
        $searchKey: String
        $offset: Int
        $limit: Int
        $filter: [TokenFilterActivityInput]
    ) {
        nftContractV2(
            id: $id
            searchKey: $searchKey
            offset: $offset
            filter: $filter
            limit: $limit
        ) {
            data {
                nftContractType {
                    id
                    name
                    type
                }
                id
                name
                symbol
                contractAddress
                networkId
                deployJobId
                mediaTxHash
                isRevealed
                nftContractDeployment {
                    delayedRevealStatus
                }
                nftTokens {
                    id
                    name
                    onChainId
                    metadataJSON
                    thumbnailImageCdnURL
                    ethereumTxId
                    editions
                    status
                    mintedAt
                    TokenDeploymentStatus
                    tokenMetadata {
                        description
                        image
                    }
                    asset {
                        currentVersion {
                            cdnUrl
                        }
                    }
                    mintOrder
                    nftTransferTxHash
                    nftTransferStatus
                }
            }
            totalCount
        }
    }
`;

export const nftContractDetailQuery = gql`
    query ($id: UUID1!) {
        getNftDraftContract(id: $id) {
            nftContractId
            nftName
            nftSymbol
            artistEmailAddress
            isContractRedeemable
            network {
                id
                name
                chainID
            }
            contractV2
            walletId
            contractAddress
            contractType {
                id
                name
                type
            }
            royalties {
                royaltyWalletAddress
                royaltyPercentage
            }
            nftDeployContract {
                isDelayedReveal
                emailNotifyFlag
                artistWalletAddress
                artistApprovalStatus
                maxEdition
                totalSupply
                redeemMaxLimit
                id
                nftContractId
                asset {
                    id
                    currentVersion {
                        cdnUrl
                        name
                        id
                        assetID
                    }
                }
                nftContract {
                    isContractNftDynamic
                    contractAddress
                    redeemContractID
                    deploymentTxHash
                    id
                    isExternal
                    isImported
                    nftContractType {
                        id
                        name
                        type
                    }
                    name
                    symbol
                    wallet {
                        id
                        name
                        address
                        network {
                            name
                        }
                    }
                    networkId
                }
                dateDeployed
                collectionType
                collectionDescription
                status
                asciiMark
                deploymentType
                mintingMethod
                delayedRevealImageId
                delayedRevealDescription
                delayedRevealTriggerTime
                baseContractJobId
                baseContractStatus
                extensionContractJobId
                extensionContractStatus
                registerExtensionJobId
                registerExtensionStatus
                hotWalletApprovalStatus
                delayedRevealJobId
                delayedRevealStatus
                deploymentFlag
                royaltiesStatus
                royaltiesJobId
                baseDeploymentTxHash
                extensionContractDeploymentTxHash
                delayedRevealDeploymentTxHash
                royaltiesDeploymentTxHash
                delayedRevealName
                isRedeemable
                redeemableStatus
                redeemableDeploymentTxHash
                redeemableCollectionName
                redeemableCollectionSymbol
                tokenHoldingAddress
                newCollectionContractAddress
            }
            isPreload
            isBaseUriDeployment
        }
    }
`;

export const getSignatureQuery = gql`
    query getSignatureMessageQuery(
        $orgID: UUID1!
        $walletAddress: String!
        $networkID: UUID1!
    ) {
        getSignatureMessage(
            orgID: $orgID
            walletAddress: $walletAddress
            networkID: $networkID
        )
    }
`;
export const loginWithSignatureQuery = gql`
    mutation loginWithSignatureMutation(
        $request: SignInRequest!
        $orgID: UUID1!
    ) {
        loginWithSignature(request: $request, orgID: $orgID) {
            token
            jwtRefreshToken
            tokenType
        }
    }
`;

export const getArtistsContractsQuery = gql`
    query getArtistContracts(
        $orgId: UUID1!
        $walletAddress: String!
        $filters: ContractFilters
    ) {
        getArtistContracts(
            orgId: $orgId
            walletAddress: $walletAddress
            filters: $filters
        ) {
            nftContractId
            nftName
            nftSymbol
            network {
                name
                chainID
                rpcURL
                manifoldCreator721ImplementationAddress
                manifoldCreator1155ImplementationAddress
            }
            royalties {
                royaltyWalletAddress
                royaltyPercentage
            }
            contractAddress
            isRedeemExtensionBaseUri
            nftDeployContract {
                status
                nftContract {
                    nftContractType {
                        type
                    }
                    isImported
                    wallet {
                        address
                    }
                }
                artistApprovalStatus
                collectionType
                dateDeployed
                hotWalletApprovalStatus
                delayedRevealStatus
                registerExtensionStatus
                extensionContractStatus
                baseContractStatus
                baseDeploymentTxHash
                hotWalletDeploymentTxHash
                artistAdminStatus
                artistAdminTxHash
                tokenHoldingAddress
                redeemClientName
                redeemableBaseContractAddAdminStatus
                redeemableRegisterExtensionStatus
                newCollectionBaseContractStatus
                redeemableStatus
                redeemableRegisterExtensionTxHash
                newCollectionBaseContractTxHash
                redeemableDeploymentTxHash
                redeemableBaseContractAddAdminTxHash
                newCollectionContractAddress
                isRedeemable
                royaltiesStatus
                royaltiesDeploymentTxHash
                redeemableCollectionName
                redeemableCollectionSymbol
                redeemMaxLimit
                redeemApprovalStatus
                redeemApprovalTxHash
                totalSupply
            }
            redeemableContractAddress
        }
    }
`;

export const nftContractTokens = gql`
    query nftToken($id: UUID1!) {
        nftToken(id: $id) {
            id
            name
            onChainId
            nftContractID
            ethereumTxId
            metadataArweaveTxId
            deployed
            royaltyBasisPoints
            assetId
            editions
            status
            thumbnailImageCdnURL
            asset {
                currentVersion {
                    id
                    name
                    cdnUrl
                    arweaveTx
                }
            }
            tokenMetadata {
                description
                customFields {
                    fieldName
                    value
                }
            }
            metadataJSON
            TokenDeploymentStatus
            nftContract {
                contractAddress
                name
                nftContractType {
                    name
                }
            }
        }
    }
`;

export const sendArtistApprovalMailMutation = gql`
    mutation ArtistAprovalNotificationMutation(
        $orgID: UUID1!
        $contractID: UUID1!
    ) {
        ArtistAprovalNotification(orgID: $orgID, contractID: $contractID)
    }
`;

export const getContractDeploymentURLQuery = gql`
    query getContractDeploymentURLQuery(
        $orgId: UUID1!
        $configType: ContractFileType
    ) {
        getContractDeploymentURL(orgId: $orgId, configType: $configType) {
            id
            erc721ProxyABI
            erc721ProxyBIN
            erc721ImplementationABI
            erc721ImplementationBIN
            erc1155ProxyABI
            erc1155ProxyBIN
            erc1155ImplementationABI
            erc1155ImplementationBIN
            redeemablesABI
            redeemablesBIN
        }
    }
`;

export const getHotWalletsQuery = gql`
    query getHotWalletsQuery(
        $orgId: UUID1!
        $contractId: UUID1!
        $contractType: ContractDeploymentType!
    ) {
        getHotWallets(
            orgId: $orgId
            contractId: $contractId
            contractType: $contractType
        ) {
            walletAddress
            contractAddress
            status
        }
    }
`;

export const getNftContractsV2Query = gql`
    query GetNftContractsV2(
        $orgId: UUID1!
        $sort: NftContractSortInput
        $filter: NftContractFilterInput
        $offset: Int
        $limit: Int
        $searchKey: String
    ) {
        getNftContractV2(
            orgId: $orgId
            sort: $sort
            filter: $filter
            offset: $offset
            limit: $limit
            searchKey: $searchKey
        ) {
            count
            nftContract {
                id
                name
                symbol
                contractAddress
                extensionAddress
                nftContractType {
                    type
                }
                nftContractDeployment {
                    collectionType
                    mintingMethod
                    delayedRevealImageId
                }
                wallet {
                    id
                    networkId
                    network {
                        id
                        name
                        chainID
                    }
                    address
                }
                network {
                    id
                    name
                    chainID
                }
                isContractNftDynamic
            }
        }
    }
`;

export const bulkUploadManifestQuery = gql`
    query bulkUploadGeneratePathManifest($contractID: UUID1!) {
        bulkUploadGeneratePathManifest(contractID: $contractID)
    }
`;

export const checkAndUploadMetaDataByTokenIdMutation = gql`
    mutation checkAndUploadMetaDataByTokenIdMutation($nftTokenId: UUID1!) {
        checkAndUploadMetaDataByTokenId(nftTokenId: $nftTokenId)
    }
`;
