import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Typography,
    Stack,
    Menu,
    MenuItem as MUIMenuItem,
    useTheme,
    Divider
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStore } from '@state/StoreProvider';
import { RouterPath, StoreProviderSkipRequests } from '@constants';
import { MixTheme } from '@styles';
import CustomTooltip from '@components/Atoms/tooltip';
import { useOrganizationStore } from '@state/OrganizationProvider';

interface MenuItemProps {
    id: string;
    src: string;
    href: string;
    collapse: boolean;
    isSelected?: boolean;
    disabled?: boolean;
    isDashboard?: boolean;
    name?: string;
    tooltip?: string;
    onClick: (id: string, event: React.MouseEvent<HTMLElement>) => void;
}

export const MenuItem = ({
    id,
    src,
    href,
    isSelected = false,
    disabled = false,
    isDashboard = false,
    name,
    collapse,
    tooltip,
    onClick
}: MenuItemProps) => {
    const router = useRouter();
    const theme: MixTheme = useTheme();
    const {
        meData,
        setSkipRequests,
        setActiveOrganizationId,
        currentOrganizationId
    } = useStore();

    const { setOrganizationHandle } = useOrganizationStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const [userOrganizations, setUserOrganizations] = useState<
        { label: string; value: string }[]
    >([]);
    const open = Boolean(anchorEl);
    const findOrganization = useCallback(
        (organizationId: string) => {
            return meData?.me?.userOrgs?.find(
                (item) => item.organization.id == organizationId
            );
        },
        [meData?.me?.userOrgs]
    );

    const selectedOrganization = useMemo(
        () => findOrganization(currentOrganizationId)?.organization?.name,
        [currentOrganizationId, findOrganization]
    );
    const selectedProfilePic = useMemo(
        () => findOrganization(currentOrganizationId)?.profilePic,
        [currentOrganizationId, findOrganization]
    );
    const onClickMovertIcon = useCallback(
        (event: React.MouseEvent<HTMLElement>, type: string) => {
            if (type == 'dashboard') {
                const formattedData = meData?.me.userOrgs
                    ?.filter((item) => item.role?.toLowerCase() == 'admin')
                    .map((item) => ({
                        label: item.organization.name,
                        value: item.organization.id
                    }));
                setUserOrganizations(formattedData);
            }
            setAnchorEl(event.currentTarget);
        },
        [meData]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onSelectOrganization = useCallback(
        (organizationId: string) => {
            setOrganizationHandle(undefined);
            setActiveOrganizationId(organizationId);
            handleClose();
            router.push(RouterPath.dashboard);
        },
        [setActiveOrganizationId, handleClose, router, setOrganizationHandle]
    );

    useEffect(() => {
        setSkipRequests({ ...StoreProviderSkipRequests, meQuery: false });
    }, [setSkipRequests]);

    // useEffect(()=> {
    //     if (!globalData.isPageLoading) {
    //         setActiveOrganizationId(StorageService.orgId.getValue()?.toString() ?? '');
    //     }
    // }, [globalData.isPageLoading, setActiveOrganizationId]);

    const handleClick = useCallback(
        (e, type?: string) => {
            // setActiveOrganizationId('', true);
            if (id != 'profile') {
                setShowToolTip(false);
            }
            e.preventDefault();
            if (id == 'dashboard' || (id == 'profile' && !type)) {
                return;
            }
            if (disabled || !onClick) {
                return;
            }
            onClick(id, e);
            if (!disabled && href) {
                router.push(href);
            }
        },
        [id, disabled, router, href, onClick]
    );

    return (
        <>
            {id == 'dashboard' && isDashboard && <Divider />}
            <CustomTooltip
                message={id == 'profile' ? 'Profile & Logout' : tooltip ?? ''}
                isShow={id != 'dashboard' && id != 'superadmin' && showToolTip}
                placement="right"
            >
                <Box
                    onMouseEnter={() => id != 'profile' && setShowToolTip(true)}
                    onMouseLeave={() =>
                        id != 'profile' && setShowToolTip(false)
                    }
                    sx={{
                        padding: '7px',
                        marginBottom:
                            id == 'dashboard' && isDashboard ? '0px' : '10px',
                        borderRadius: '5px',
                        backgroundColor:
                            isSelected && id != 'dashboard'
                                ? theme?.global?.hoverBackground
                                : '',
                        '&:hover': {
                            backgroundColor: disabled
                                ? ''
                                : theme?.global?.hoverBackground,
                            cursor:
                                disabled && id != 'dashboard'
                                    ? 'not-allowed'
                                    : 'pointer'
                        },
                        display: 'flex',
                        width: isDashboard ? '100%' : 'inherit',
                        overflow: 'hidden',
                        alignItems: 'center',
                        justifyContent: collapse ? 'initial' : 'space-around'
                    }}
                    onClick={handleClick}
                >
                    {id === 'dashboard' && (
                        <Typography
                            onClick={(e) => onClickMovertIcon(e, 'dashboard')}
                            variant="h5"
                            sx={{
                                fontWeight: 700,
                                color: theme.color?.white,
                                backgroundColor: theme.color?.black,
                                borderRadius: '4px',
                                padding: '6px',
                                fontSize: '16px',
                                width: 22,
                                minWidth: 22,
                                minHeight: 22,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {selectedOrganization
                                ?.toString()
                                ?.slice(
                                    0,
                                    selectedOrganization?.length > 1 ? 2 : 1
                                )}
                        </Typography>
                    )}
                    {id == 'superadmin' ? (
                        <Image
                            src={
                                isDashboard && collapse
                                    ? '/images/icons/sidebar/full-logo.svg'
                                    : '/images/icons/sidebar/mojito-logo.svg'
                            }
                            width={isDashboard && collapse ? 150 : 30}
                            height={32}
                        />
                    ) : (
                        id != 'dashboard' && (
                            <Box>
                                <Image
                                    onClick={(e) =>
                                        id == 'profile'
                                            ? onClickMovertIcon(e, 'profile')
                                            : undefined
                                    }
                                    src={
                                        id == 'profile'
                                            ? selectedProfilePic ?? src
                                            : src
                                    }
                                    width={23}
                                    height={23}
                                />
                            </Box>
                        )
                    )}

                    {collapse === true && isDashboard && name && (
                        <Typography
                            variant={`${id == 'dashboard' ? 'subtitle1' : 'body1'}`}
                            sx={{
                                overflow: id == 'profile' ? 'hidden' : '',
                                textOverflow: 'ellipsis',
                                marginLeft: '20px',
                                fontWeight: id == 'dashboard' ? 700 : '',
                                width:
                                    id == 'dashboard'
                                        ? '90px'
                                        : id == 'profile'
                                          ? '110px'
                                          : ''
                            }}
                        >
                            {id == 'dashboard'
                                ? selectedOrganization
                                : id == 'profile'
                                  ? meData?.me?.user?.name
                                  : name}
                        </Typography>
                    )}

                    {collapse === true &&
                        (id == 'dashboard' || id == 'profile') &&
                        isDashboard && (
                            <Stack onClick={(e) => onClickMovertIcon(e, id)}>
                                <MoreVertIcon />
                            </Stack>
                        )}
                    <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        open={open && id == 'dashboard'}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox'
                        }}
                    >
                        {userOrganizations?.map((item) => (
                            <MUIMenuItem
                                key={item.value}
                                onClick={() => onSelectOrganization(item.value)}
                            >
                                {item.label}
                            </MUIMenuItem>
                        ))}
                    </Menu>
                    <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        open={open && id == 'profile'}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox'
                        }}
                    >
                        <MUIMenuItem>
                            <Typography
                                variant="body1"
                                onClick={(e) => handleClick(e, 'logout')}
                            >
                                Logout
                            </Typography>
                        </MUIMenuItem>
                    </Menu>
                </Box>
            </CustomTooltip>
            {id == 'dashboard' && isDashboard && (
                <Divider sx={{ marginBottom: '10px' }} />
            )}
        </>
    );
};

export default MenuItem;
