import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from '@components/shared/Sidebar';
import { LoadingComponent } from '@components/shared';
import { Button, Toasttypes } from '@components/Atoms';
import { MixTheme } from '@styles';
import bg from '@public/images/background.svg';
import { makeStyles } from '@layouts/authentication/login/login.styles';
import { useStyle } from '@styles';
import Image from 'next/image';
import { useMeStore } from '@state/MeProvider';
import TermsAndConditionModal from '@components/shared/TermsAndCondition';
import { useMutation } from '@apollo/client';
import { acceptTermsAndConditionMutation } from '@graphql/mutation/mutation';
import { useGlobalState } from '@state/GlobalContext';
import { useToast } from '@state/ToastProvider';
import { RouterPath } from '@constants';
import { StorageService } from '@services/StorageService';

type HomeLayoutProps = {
    children: React.ReactNode;
    isOpened: boolean;
    isPageLoading: boolean;
};

const notAllowedRoutes = [
    '/',
    '/login',
    '/signup',
    '/verify',
    '/organization',
    '/createsite/[contentFullId]/homePage',
    '/createsite/[contentFullId]/aboutUs',
    '/createsite/[contentFullId]/content',
    '/createsite/[contentFullId]/faq',
    '/createsite/[contentFullId]/collection',
    '/creator-approval',
    '/creator-approval/login/[id]',
    '/newuser'
];

const HomeLayout = ({ children, isOpened, isPageLoading }: HomeLayoutProps) => {
    const router = useRouter();
    const { isLoading, isAuthenticated } = useAuth0();
    const { meData, loading: meLoading } = useMeStore();
    const { setToast } = useToast();
    const { setData: setGlobalData } = useGlobalState();
    const [acceptTermsMutation] = useMutation(acceptTermsAndConditionMutation);
    const [containerWidth, setContainerWidth] = useState<string>('');
    const [width, setWidth] = useState<number | undefined>();
    const showTermsAndCondition = useMemo(
        () =>
            !meData?.me?.user?.isTermsAccepted && !meLoading && !isPageLoading,
        [meData?.me?.user?.isTermsAccepted, meLoading, isPageLoading]
    );
    const theme: MixTheme = useTheme();
    const { styles } = useStyle(makeStyles);
    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);
    const handleWindowSizeChange = useCallback(() => {
        setWidth(window.innerWidth);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [handleWindowSizeChange]);

    useEffect(() => {
        if (
            !isAuthenticated &&
            router.pathname != RouterPath.artistLogin &&
            router.pathname != RouterPath.login &&
            !isLoading
        ) {
            localStorage.clear();
            router.push(RouterPath.login);
        }
    }, [isAuthenticated, router, isLoading]);
    // user have no organization or user who haven't admin role in any organization.
    useEffect(() => {
        const isNotAnAdminUser =
            meData?.me?.userOrgs?.filter(
                (item) => item.role?.toLowerCase() == 'admin'
            )?.length == 0;
        if (meData?.me?.userOrgs?.length === 0 || isNotAnAdminUser) {
            router?.push(RouterPath?.newUser);
        }
    }, [meData?.me?.userOrgs, router]);

    const handleNavigate = useCallback(() => {
        window.location.href = 'https://mojito.xyz/';
    }, []);

    const isMobile = useMemo(() => width <= 768, [width]);

    const onSubmitTermsAndCondition = useCallback(async () => {
        try {
            await acceptTermsMutation({
                variables: {
                    userId: meData?.me?.user?.id
                }
            });
            setGlobalData((prev) => ({
                ...prev,
                isRefetchMe: true
            }));
        } catch (err: any) {
            setToast(err?.message, Toasttypes.ERROR);
        }
    }, [meData?.me?.user?.id, acceptTermsMutation, setGlobalData, setToast]);

    useEffect(() => {
        setGlobalData((prev) => ({
            ...prev,
            isPageLoading: isLoading || isPageLoading
        }));
    }, [isLoading, isPageLoading, setGlobalData]);

    if (isLoading || isPageLoading) {
        return <LoadingComponent show={isLoading || isPageLoading} />;
    }

    if (
        showTermsAndCondition &&
        !isLoading &&
        !notAllowedRoutes.includes(router.pathname) &&
        isAuthenticated
    ) {
        return (
            <TermsAndConditionModal
                show={showTermsAndCondition}
                onSubmit={onSubmitTermsAndCondition}
            />
        );
    }

    if (isMobile) {
        return (
            <Stack
                sx={{
                    backgroundImage: `url(${bg.src})`,
                    backgroundSize: 'cover'
                }}
            >
                <Stack
                    direction={'row'}
                    padding={'30px 0px'}
                    justifyContent={'center'}
                    sx={styles.header}
                    alignItems={'center'}
                    height={'100vh'}
                >
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        maxWidth={'680px'}
                        margin={'auto 25px'}
                    >
                        <Box
                            border={`4px solid ${theme?.color?.white}`}
                            padding={'20px 32px'}
                            borderRadius={'4px'}
                        >
                            <Image
                                src={'/images/mojito-logo.svg'}
                                width={'124px'}
                                height={'32px'}
                            />
                        </Box>
                        <Card
                            sx={{
                                ...styles.loginCard,
                                width: '-webkit-fill-available',
                                padding: '24px'
                            }}
                        >
                            <CardContent sx={{ padding: '16px 0px' }}>
                                <Typography
                                    variant={'h4'}
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '24px',
                                        paddingBottom: '24px'
                                    }}
                                >
                                    {' '}
                                    Not Available on Mobile
                                </Typography>
                                <Typography variant={'body2'}>
                                    Mint 2.0 is currently available for desktop.
                                    Please use a larger screen.
                                </Typography>
                                <Stack sx={{ width: '100%' }}>
                                    <Button
                                        onClick={handleNavigate}
                                        title="Back To Mojito Website"
                                        hoverBackgroundColor={
                                            theme?.color?.white
                                        }
                                        backgroundColor={theme?.color?.white}
                                        color={theme?.global?.hover}
                                        margin={'16px 0 0 0'}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack minHeight="100vh">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                {!notAllowedRoutes.includes(router.pathname) && (
                    <Sidebar
                        isOpened={isOpened}
                        setContainerWidth={setContainerWidth}
                    />
                )}
                <Box
                    sx={{
                        width:
                            notAllowedRoutes.includes(router.pathname) ||
                            !containerWidth
                                ? '100%'
                                : `calc( 100% - ${containerWidth})`
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Stack>
    );
};
export default HomeLayout;
